import React, { useState, useEffect, useMemo } from 'react';
import { Button, Transfer, Modal, Row, Col, Tooltip, Radio, Typography, Space, Select } from 'antd';
import { SettingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SelectAccountsTransfer = (props) => {

    const [transferOpen, setTransferOpen] = useState(false);
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);


    useEffect(() => {
        if (targetKeys !== props.chosen[selectedAccount]) setTargetKeys(props.chosen[selectedAccount])
    }, [selectedAccount])

    const onChange = (nexttargetKeys, direction, moveKeys) => {
        setTargetKeys(nexttargetKeys);
    };

    const onSelectChange = (sourceselectedKeys, targetselectedKeys) => {
        setSelectedKeys([...sourceselectedKeys, ...targetselectedKeys]);
    };

    const onSelectAccountChange = (value) => {
        setSelectedAccount(value)
    };

    const handleOk = () => {
        let new_consent_data = props.chosen
        new_consent_data[selectedAccount] = targetKeys
        console.log('new_consent_data', new_consent_data)
        props.onAccept(new_consent_data)
        setTransferOpen(false)
    }

    const handleCancel = () => {
        setTargetKeys(props.chosen[selectedAccount])
        setTransferOpen(false)
    }

    const data = useMemo(() => Object.keys(props.chosen).map((value, i) => ({
        key: value,
        title: value,
        value: value,
        label: value,
        description: "",
    })), [props.chosen])


    return (
        <>
            <Tooltip mouseLeaveDelay={0} title="Edit" placement="top">
                <Button 
                    size="small" 
                    icon={<SettingOutlined />} 
                    onClick={() => setTransferOpen(true)}
                    style={{marginRight: 4}}
                    disabled={!!props.disabled}
                >
                </Button>
            </Tooltip>
            <Modal
                title={
                    <Space size={8}>
                        <span style={{display: "inline-block", marginRight: 8}}>
                            {"Select accounts to grant view data consent"}
                        </span>
                    </Space>
                }
                open={transferOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                width={600}
                centered
                style={{}}
            >
                <Row>
                    <Col xl={24} md={24} style={{marginBottom: 4}}>
                        <Text>Select account to alter it's viewing rights</Text>
                        <Select showSearch options={data} placeholder="Select account" style={{width: "100%"}} onChange={onSelectAccountChange} defaultValue={selectedAccount} />
                    </Col>
                </Row>
                <Row>
                    <Col xl={24} md={24} style={{marginBottom: 4}}>
                        <Transfer
                            dataSource={data}
                            titles={['Available accounts', 'View data consent granted']}
                            showSearch
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            render={(item) => item.title}
                            listStyle={{
                                width: 220,
                                height: 400,
                            }}
                        />
                    </Col>
                </Row>
            </Modal>
        
        </>
    );
};

export default SelectAccountsTransfer;